module controllers {
    export module sales {
        interface ISalesOrderScope extends ng.IScope {
            IsLoading: boolean;
            ShowGrid: boolean;
        }

        export class salesOrderListCtrl {
            static $inject = ["$scope", 'salesOrderService', '$rootScope', 'generalService', '$q', 'entityService', '$state', 'customerService', 'consigneeService', 'currencyService',
                'statusService', '$timeout', '$transitions'];

            soSearch: interfaces.sales.ISalesOrderSearch = {
                SOStatusId: 0
            };

            searchAccordian: boolean = false;

            fromDateOpen: boolean = false;
            toDateOpen: boolean = false;
            SLADateOpen: boolean = false;
            filterDateOpen: boolean = false;

            public searchSupplier: interfaces.applicationcore.IDropdownModel = null;
            public searchCurrency: interfaces.applicationcore.IDropdownModel = null;

            dropdownsObject = {
                Entities: Array<interfaces.applicationcore.IEntity>()
            };           

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            }; 

            statusList: interfaces.applicationcore.IDropdownModel[] = [];

            apiSOList: uiGrid.IGridApi;
            selectedSO: uiGrid.IGridRow;

            filterNames: string[] = ["OwnerEntityName", "OrderNumber", "OrderDate",
                "CustomerName", "TypeName", "CurrencyCode",
                "IncotermCode", "UnLoadingCountryCode", "StatusCode", "SLADate","ShipByDate","EstimatedDateOfArrival","ConsigneeName","ResponsibleUser"];
            filterList: interfaces.applicationcore.IKeyValue[] = [];

            sortName: string;
            sortDirection: string;

            constructor(
                private $scope: ISalesOrderScope,
                private salesOrderService: interfaces.sales.ISalesOrderService,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private customerService: interfaces.master.ICustomerService,
                private consigneeService: interfaces.master.IConsigneeService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private statusService: interfaces.master.IStatusService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition
            ) {
                this.loadControls();  

                //Reloads SO after an update to SKU
                $transitions.onSuccess({
                    to: 'auth.SalesOrders',
                    from: 'auth.SalesOrders.Update.**'
                }, () => {
                    this.search_click();
                });                             
            }            

            loadControls() {
                this.$scope.ShowGrid = false;

                let controlPromises = [
                    this.loadEntities(),
                    this.loadStatuses()
                ]


                this.$q.all(controlPromises).then((data) => {
                    this.$scope.ShowGrid = true;
                    this.soSearch = { SOStatusId: 0}
                    this.DoSearch();
                });

            }

            loadEntities() {
                return this.entityService.getList().query((successList) => {
                    this.dropdownsObject.Entities = successList;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;

            }

            loadStatuses() {
                return this.statusService.getStatusDropdownList().query({ type: Enum.EnumStatusType.SalesOrder }, (result) => {
                    this.statusList = result;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }


            loadCurrencies() {
                return this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }
            
            loadCustomers(searchText: string) {
                if (this.$rootScope.Entity.Id) {
                    return this.customerService.getDropdownList(this.$rootScope.Entity.Id, searchText).query(() => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }
                else {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        return this.customerService.getDropdownList(resultEntity.Id, searchText).query(() => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }
            }

            loadConsignees(searchText: string) {
                if (this.$rootScope.Entity.Id) {
                    return this.consigneeService.getDropdownList(this.$rootScope.Entity.Id, searchText).query(() => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }
                else {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        return this.consigneeService.getDropdownList(resultEntity.Id, searchText).query(() => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }
            }
            
            search_click() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;
                this.DoSearch();
            }

            showAll_click() {
                this.$scope.ShowGrid = true;
                this.$scope.IsLoading = true;
                this.searchAccordian = false;
                this.searchSupplier = null;
                this.soSearch = {};

                if (this.apiSOList !== undefined) {
                    this.apiSOList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiSOList.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            downloadToExcel() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.soSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiSOList) {

                    var grid = this.apiSOList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }
                
                var params = {
                    connectSearch: searchObject
                }

                this.salesOrderService.getSalesOrderListExcel(params).then(() => {
                    this.$scope.IsLoading = false;
                }, (data) => {
                    this.$scope.IsLoading = false;
                    this.gvwSOList.data = [];
                    this.gvwSOList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            getClassName(grid, row, col, columnName, CONSTANTS) {
                var className = "alert alert-danger";
                return className;
            }

            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if ( this.apiSOList && ( this.apiSOList.grid.getColumn(name).filters[0].term || this.apiSOList.grid.getColumn(name).filters[0].term == "")) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiSOList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiSOList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.apiSOList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    var column = this.apiSOList.grid.getColumn(this.sortName);

                    if (column.sort.direction != this.sortDirection) {
                        this.apiSOList.grid.sortColumn(column, this.sortDirection);
                    }

                    this.apiSOList.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            gvwSOList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) 
                
                    this.apiSOList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiSOList.selection.selectRow(newCol.row.entity);
                        });
                    });
                },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                }, {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                            <div> 
                                <button type="button" ui-sref="auth.SalesOrders.Update({ soId: row.entity.Id })" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "OwnerEntityName",
                    displayName: "Entity",
                    field: "OwnerEntityCode",
                    width: 60,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "OrderNumber",
                    displayName: "SO Number",
                    field: "OrderNumber",
                    width: 250,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "OrderDate",
                    displayName: "SO Date",
                    field: "OrderDate",
                    width: 150,
                    type: "date",
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="orderDate"></gts-date>
                            </div>`,
                }, {
                    name: "CustomerName",
                    displayName: "Customer Name",
                    field: "CustomerName",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                            cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }, {
                    name: "ConsigneeName",
                    displayName: "Consignee Name",
                    field: "ConsigneeName",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                            cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }, {
                    name: "TypeName",
                    displayName: "SO Type",
                    field: "TypeName",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                            cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }, {
                    name: "CurrencyCode",
                    displayName: "Currency",
                    field: "Currency",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },{
                    name: "Amount",
                    displayName: "Amount",
                    field: "TotalForeignCurrencyValue",
                    width: 105,
                    cellClass: 'text-right',
                    cellFilter: "number: 2",   enableFiltering: false
                }, {
                    name: "IncotermCode",
                    displayName: "Incoterm",
                    field: "Incoterm",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "UnLoadingCountryCode",
                    displayName: "Destination",
                    field: "UnLoadingCountryCode",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "ShipByDate",
                    displayName: "Ship By Date",
                    field: "ShipByDate",
                    width: 150,
                    type: "date",
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="ShipByDate"></gts-date>
                                        
                            </div>`,
                }, {
                    name: "EstimatedDateOfArrival",
                    displayName: "ETA",
                    field: "EstimatedDateOfArrival",
                    width: 150,
                    type: "date",
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="EstimatedDateOfArrival"></gts-date>
                                        
                            </div>`,
                }
                , {
                    name: "StatusCode",
                    displayName: "Status",                    
                    width: 200,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                            cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                }, {
                    name: "SLADate",
                    displayName: "SLA Date",
                    field: "SLADate",
                    width: 150,
                    type: "date",
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                        var slaDate = grid.getCellValue(row, col);
                        if (moment().diff(slaDate, 'days') > 0) {
                            return 'slaDateBreach';
                        }
                        else
                        {
                            return '';
                        }
                            
                    } ,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="SLADate"></gts-date>
                                        
                        </div>`,
                    },
                    , {
                        name: "ResponsibleUser",
                        displayName: "Responsible User",
                        field: "ResponsibleUser",
                        width: 150,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                                cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    }]
            };

            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiSOList = gridApi;

                this.apiSOList.core.on.filterChanged(this.$scope, () => { this.SOListFilterChange(gridApi) });
                this.apiSOList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.SOListShortChange(gridApi);

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });
                this.apiSOList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.SOListPageChange(newPage, pageSize) });

                this.apiSOList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.$timeout(() => {
                        this.selectedSO = selected;
                    });
                });

                this.$timeout(() => {
                    if (this.selectedSO)
                        this.apiSOList.selection.selectRow(this.selectedSO.entity);
                });
            }

            SOListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            SOListShortChange(gridApi) {
                this.DoSearch();
            }

            SOListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if(!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if(keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });

                this.DoSearch();
            }

            DoSearch() {
                this.$scope.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                angular.forEach(this.soSearch, (n, key) => {
                    if (n != null || n != undefined) {
                        if (n["Id"]) {
                            searchObject.filters.push({ Name: key, Value: n.Id });
                        } else {
                            searchObject.filters.push({ Name: key, Value: n });
                        }
                    }
                });

                if (this.apiSOList) {
                    var grid = this.apiSOList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    pageNumber: this.paginationOptions.pageNumber
                }

                this.salesOrderService.getSalesOrderList().query(params, (data) => {
                    this.$scope.IsLoading = false;
                    this.gvwSOList.data = data;

                    if (data[0]) {
                        this.gvwSOList.totalItems = data[0].NumberRecords;
                    } else {
                        this.gvwSOList.totalItems = 0;
                    }

                    this.getFilters();
                    this.setFilters();

                    this.$timeout(() => {
                        if (this.selectedSO) {
                            var index = _.findIndex(this.gvwSOList.data, (o) => { return o.Id === this.selectedSO.entity.Id });
                            this.apiSOList.selection.selectRow(this.gvwSOList.data[index]);
                        }
                    });

                }, (data) => {
                    this.$scope.IsLoading = false;
                    this.gvwSOList.data = [];
                    this.gvwSOList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            printDocuments() {
                if (this.apiSOList && this.apiSOList.selection.getSelectedRows().length > 0) {
                    var selected = this.apiSOList.selection.getSelectedRows()[0];
                    this.$state.go("auth.SalesOrders.Update.Documents", { soId: selected.Id });
                } else {
                    this.generalService.displayMessage("Please select a Sales Order", Enum.EnumMessageType.Warning);
                }
            }
        };

        angular.module("app").controller("salesOrderListCtrl", controllers.sales.salesOrderListCtrl);
    }
}